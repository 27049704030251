import Typebot from '@typebot.io/js/dist/web.js';
import Cookies from 'js-cookie';

document.querySelectorAll("typebot-standard").forEach(( typebotElement ) => {

    Typebot.initStandard({
        typebot: typebotElement.dataset.identifier,
        apiHost: typebotElement.dataset.apihost,
        id: typebotElement.id,
        prefilledVariables: {
            'utm_source': Cookies.get("ms_utm_source"),
            'utm_content': Cookies.get("ms_utm_content"),
        },
    })
});